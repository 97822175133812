import React, { Component } from 'react';
import Config from '../config';

export default class AdminRedirect extends Component {
  componentDidMount = () => {
    window.location.href = `${Config.loginUrl}`;
  };

  render() {
    return (
      <div className='content'>
        <p>
          Redirecting to the admin screen now. If it doesn't redirect then
          <a href={`${Config.apiUrl}`}> click here</a>.
        </p>
      </div>
    );
  }
}
